import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../../product/components/atoms/Inputs/CustomInput'
import CustomSelect from '../../../product/components/atoms/Inputs/CustomSelect'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import '../Dashboard.css'
import DashboardLayout from '../DashboardLayout'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { Link, NavLink } from 'react-router-dom'
import { mapData } from '../../../product/common/components'
import { useFormik } from 'formik'
import Popup from '../../../custom/components/organisms/Popup'
import * as Yup from 'yup'
import AuthContext from '../../../product/context/auth/authContext'
import UserContext from '../../../product/context/user/userContext'
import CommonContext from '../../../custom/context/common/commonContext'
import PhoneValidation from '../../Registration/phoneValidation'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddressDetails from '../../../custom/components/organisms/Address'
import CardDetails from '../../../custom/components/organisms/CardDetails'
import StripeCardContext from '../../../product/context/stripe/card/cardContext'
import StripeCustomerContext from '../../../product/context/stripe/customer/customerContext'
import PhoneValidationComponent from '../../../product/components/species/components/user/PhoneValidationComponent'

const MyAccount = () => {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const commonContext = useContext(CommonContext)
    const { allNotifications, configFeatures } = commonContext
    const { user, loadUser, isAuthenticated } = authContext
    const { updateProfile, updatePreference, responseStatus: responseStatusUser } = userContext
    const {
        customer_details,
        createStripeCustomer,
        getStripeCustomer,
        responseStatus: customerResponseStatus,
    } = useContext(StripeCustomerContext)
    const [reload, setReload] = useState(false)

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }
    const {
        get_all_card_details,
        getAllStripeCards,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(StripeCardContext)
    const [phoneVerify, setPhoneVerify] = React.useState(false)
    const [bulkPopup, setBulkPoup] = useState(false)

    const [currentCountry, setCurrentCountry] = useState()
    const [address, setAddress] = useState([])
    const [cards, setCards] = useState([])

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const changePhoneVerify = () => {
        formik.values.verify_code = ''
        setPhoneVerify(!phoneVerify)
    }
    const toggleFullScreenPopup = (popup, status, data) => {
        setManage({ popup, status, data })
    }
    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim('Remove trailing and leading whitespaces')
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .typeError('Required!')
            .required('First Name is Required!'),
        last_name: Yup.string()
            .trim('Remove trailing and leading whitespaces')
            .matches(/^[a-zA-Z ]*$/g, 'No Special Characters and Numbers Allowed')
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .typeError('Required!')
            .required('Last Name is Required!'),

        phone: Yup.string()
            .trim()
            .min(15, 'Incorrect format')
            .max(20, 'Incorrect format')
            .matches(/^[0-9- +()]*$/g, 'Only Numbers Are Allowed')
            .required('Required'),
        email: Yup.string().email(),
        tax_exempt_id: Yup.string().trim().max(50, 'maximum 50 characters allowed'),
        /*address1: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'some_kind_of_special_characters_not_allowed')
            .max(250, 'maximum_250_characters')
            .required('Required'),
        address2: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'some_kind_of_special_characters_not_allowed')
            .max(250, 'maximum_250_characters'),
       
        // .required('Required')
        country: Yup.string().trim().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, 'the_positive_number_only')
            .max(6, 'maximum_6_characters')
            .required('Required'),*/
    })

    const validationPassword = Yup.object({
        change_password: Yup.boolean(),
        current_password: Yup.string().required('Required'),
        new_password: Yup.string().min(8, 'Minimum 8 characters').required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords Not Match')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            address1: '',
            address2: '',
            phone: '+',
            email: '',
            tax_exempt_id: '',
            verify_code: '',
            phone_verified: 0,
            verified_phonenum: '',
            /*avatar: [],
            city: user.city ? user.city : '',
            state: user.state ? user.state : '',
            zip: user.zip ? user.zip : '',
            country: user.country ? user.country : '231',
            phone_verified: user ? user.phone_verified : 0,
            verified_phonenum: user ? user.verified_phonenum : '',
            phone_verifysent: user ? user.verifysent : 0,
            verify_code: user ? user.verify_code : '',
            stripe_id: user.stripe_id ? user.stripe_id : null,*/
            // emirates_id: '',
            // passport_number: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            const castValues = validationArray.cast(values)
            updateProfile(castValues)
            // if (user.phone === castValues.phone) {
            //     updateProfile(castValues)
            // } else if (
            //     castValues.phone_verified === 1 &&
            //     formik.values.verified_phonenum === formik.values.phone
            // ) {
            //     updateProfile(castValues)
            // } else {
            //     setPhoneVerify(true)
            // }
        },
    })
    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                    //formikChange.resetForm()
                }
            }
        }
    }, [responseStatusUser])
    function handleSubmit() {
        createStripeCustomer(
            {
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                description: 'TEST CUSTOMER',
                email: formik.values.email,
                name: 'name',
            },
            true,
        )
        /*formik.values.first_name + ' ' + formik.values.last_name,*/
    }
    const getStripeCards = () => {
        getAllStripeCards({
            account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
            customer_id: user.stripe_id,
            object: 'card',
        })
        toggleFullScreenCardPopup(false, 'new', null)
    }

    useEffect(() => {
        if (formik.values.phone_verified === 1) {
            formik.submitForm()
        }
    }, [formik.values.phone_verified])

    useEffect(() => {
        if (responseStatusCard) {
            if (
                responseStatusCard.from === 'card_source_create' ||
                responseStatusCard.from === 'card_source_update' ||
                responseStatusCard.from === 'card_source_delete'
            ) {
                if (responseStatusCard.status === 'success') {
                    getStripeCards()
                }
            }
            //            setIsLoading(false)
        } else {
            console.log(responseStatusCard, 'responseStatusCard')
        }
        return () => {
            clearResponseCard()
        }
    }, [responseStatusCard])

    useEffect(() => {
        if (user) {
            console.log(user)
            formik.values.first_name = user.first_name
            formik.values.last_name = user.last_name
            formik.values.address1 = user.address1
            formik.values.address2 = user.address2
            formik.values.phone = user.phone
            formik.values.phone_verified = 0
            formik.values.verified_phonenum = formik.values.verified_phonenum
            formik.values.email = user.email
            formik.values.city = user.city
            formik.values.country = user.country
            formik.values.state = user.state
            formik.values.zip = user.zip
            formik.values.tax_exempt_id = user.tax_exempt_id || ''
            formik.values.stripe_id = user.stripe_id ? user.stripe_id : null
            formikPreference.values.email_prefer =
                user.email_notification && user.email_notification.split(',').length
                    ? user.email_notification.split(',')
                    : []
            formikPreference.values.sms_prefer =
                user.sms_notification && user.sms_notification.split(',').length
                    ? user.sms_notification.split(',')
                    : []

            // formik.values.avatar = user.avatar
            //     ? [`${user.avatar}`]
            //     : ['file_upload-1625693520372_947677.jpeg']
            setReload(!reload)
        }
        if (user) {
            if (!user.stripe_id) {
                handleSubmit()
            } else {
                getStripeCards()
            }
        }
    }, [user])
    useEffect(() => {
        console.log(customerResponseStatus, 'cus11')
        if (
            customerResponseStatus &&
            customerResponseStatus.from == 'create' &&
            customerResponseStatus.status == 'success'
        ) {
            console.log(customerResponseStatus.data.id, 'customerResponseStatus')
            formik.values.stripe_id = customerResponseStatus.data.id
            updateProfile({ stripe_id: customerResponseStatus.data.id }, true)
        }
    }, [customerResponseStatus])
    // useEffect(() => {
    //     setBulkPoup(false)
    // }, [formik.values.avatar])
    useEffect(() => {
        if (get_all_card_details) {
            console.log(get_all_card_details, 'get_all_card_details')
            setCards(get_all_card_details.records)
        }

        return () => {
            setCards([])
        }
    }, [get_all_card_details])
    const formikChange = useFormik({
        initialValues: {
            change_password: 1,
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationPassword,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })
    const setUserAddress = () => {
        setAddress(user.address_details.filter((inner) => inner.status === 'active'))
        toggleFullScreenPopup(false, 'new', null)
    }
    useEffect(() => {
        if (user) {
            if (user.address_details) {
                console.log(user.address_details, 'user_address_details')
                setUserAddress()
            }
        }
    }, [user && user.address_details])
    const formikPreference = useFormik({
        initialValues: {
            email_prefer: [],
            sms_prefer: [],
        },
        onSubmit: (values) => {
            updatePreference(values)
        },
    })
    const emailPreference = [
        {
            name: 'email_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.email === 1),
            formik: formikPreference,
        },
    ]
    useEffect(() => {
        console.log(formik.values, 'fmi')
    }, [formik.values])
    const smsPreference = [
        {
            name: 'sms_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.sms === 1),
            formik: formikPreference,
        },
    ]

    const changePassword = [
        {
            label: 'Current Password',
            name: 'current_password',
            type: 'password',
            placeholder: 'Enter Your Current Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: 'Password',
            name: 'new_password',
            type: 'password',
            placeholder: 'Enter Your Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: 'Confirm Password',
            name: 'confirm_password',
            type: 'password',
            placeholder: 'Re-Enter Your Password',
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
    ]
    const handleFileUpload = (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: reader.result,
            })
        }
        reader.readAsDataURL(file)
    }
    // const uploadImage = [
    //     {
    //         type: 'uploadDropZone',
    //         formik: formik,
    //         name: 'avatar',
    //         titleText: 'Upload profile image',
    //         innerText: 'Recommended dimensions: 380px * 380px',
    //         class: 'col-12',
    //         folder: 'profile',
    //         multiple: false,
    //         accept: 'image/*',
    //     },
    // ]
    const profile = [
        {
            label: 'First Name',
            type: 'text',
            placeholder: 'Enter Your First Name',
            class: 'col-md-6 col-sm-12',
            name: 'first_name',
            formik: formik,
        },
        {
            label: 'Last Name',
            placeholder: 'Enter Your Last Name',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'last_name',
            formik: formik,
        },
        {
            label: 'Email Address',
            placeholder: 'Enter Your Email Address',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            disabled: true,
            name: 'email',
            formik: formik,
        },
        {
            label: 'Phone Number',
            placeholder: 'Phone Number',
            class: 'col-md-6 col-sm-12',
            type: 'phone',
            name: 'phone',
            formik: formik,
            countryCodeEditable: false,
            // disabled: true,
        },
        {
            label: 'Tax Exempt ID',
            placeholder: 'Enter Your Tax Exempt id',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'tax_exempt_id',
            formik: formik,
        },
        /*{
            label: 'Address Line 1',
            placeholder: 'Enter address line 1',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'address1',
            formik: formik,
        },
        {
            label: 'Address Line 2',
            placeholder: 'Enter address line 2',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'address2',
            formik: formik,
        },
        {
            label: 'country',
            placeholder: 'Select Country',
            class: 'col-md-6 col-sm-12',
            type: 'select',
            options: csc.getAllCountries().map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.id
                return busCategoryChanged
            }),
            name: 'country',
            formik: formik,
        },
        {
            label: 'state',
            placeholder: 'select_your_state',
            class: 'col-md-6 col-sm-12',
            type: 'select',
            options: csc
                .getStatesOfCountry(formik.values.country ? formik.values.country : 231)
                .filter((state) => global.ignoreStates.indexOf(state.id) === -1)
                .map((buscat) => {
                    let busCategoryChanged = {}

                    if (buscat.name === 'Abu Zabi') {
                        busCategoryChanged.show = 'Abu Dhabi'
                    } else {
                        busCategoryChanged.show = buscat.name
                    }
                    busCategoryChanged.value = buscat.id
                    return busCategoryChanged
                }),
            name: 'state',
            formik: formik,
        },
        {
            label: 'city',
            placeholder: 'enter_your_city',
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'city',
            formik: formik,
        },
        {
            label: 'Zipcode',
            placeholder: 'Enter your zip code',
            class: 'col-md-3 col-sm-12',
            type: 'number',
            name: 'zip',
            formik: formik,
        },*/
    ]

    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                <div className="accChild">
                    {/* <div className="prflImgWrpr">
                        <img
                            className="prflImg"
                            src={
                                formik.values.avatar.length
                                    ? `${global.site_url}/uploads/profile/${formik.values.avatar}`
                                    : `${global.site_url}/uploads/profile/file_upload-1625693520372_947677.jpeg`
                            }
                            alt=""
                        />
                        {console.log('profile-image------>', formik.values.avatar)}
                        <Button onClick={() => setBulkPoup(true)} className="edtPrflBtn">
                            <EditIcon />
                        </Button>
                    </div> */}
                    <h4>{'Profile Details'}</h4>

                    <div className="row">{Object.values(mapData(profile))}</div>
                </div>
                <PrimaryButton type="submit" id="profile_savebutton" label={'Save'} />
                {/* <Popup
                    modaltitle="Update profile picture"
                    open={bulkPopup}
                    handleClose={() => setBulkPoup(false)}
                >
                    <div className="row">{Object.values(mapData(uploadImage))}</div>
                </Popup> */}
            </form>
            <div className="savedAddressCnt mt-5">
                <h2 className="dashTitle mb-3">Saved Addresses</h2>
                <Link
                    className="btn addNewButton"
                    onClick={() => toggleFullScreenPopup(true, 'update', null)}
                >
                    <span id="addnewaddress_button" className="material-icons mr-2">
                        add
                    </span>
                    Add new address
                </Link>
                <div className="savedAddress">
                    {address &&
                        address.map((data, index) => (
                            <div className="savedAddressCard" key={index}>
                                <h4>{data.first_name + ' ' + data.last_name}</h4>
                                <address>
                                    {data.address1} <br /> {data.address2}
                                </address>
                                <span>
                                    {data.city},{data.state},{data.zip}
                                </span>
                                {data.is_primary == 1 && (
                                    <div className="dfltCard">
                                        DEFAULT{' '}
                                        <span className="material-icons ml-1">check_circle</span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-end mt-3">
                                    <Button
                                        id="editaddress_button"
                                        onClick={() => toggleFullScreenPopup(true, 'update', data)}
                                    >
                                        Edit
                                    </Button>

                                    {console.log(data, 'data address')}
                                    {data.is_primary == 0 && (
                                        <>
                                            <Button
                                                id="make_default_address"
                                                onClick={() =>
                                                    toggleFullScreenPopup(true, 'default', data)
                                                }
                                            >
                                                Make Default
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        id="deleteaddress_button"
                                        onClick={() => toggleFullScreenPopup(true, 'delete', data)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="savedCardsCnt mt-5">
                <h2 className="dashTitle mb-3">Credit Cards</h2>
                <Link
                    id="addnewcard_button"
                    className="btn addNewButton"
                    onClick={() => toggleFullScreenCardPopup(true, 'new', null)}
                >
                    <span className="material-icons mr-2">add</span>Add new Credit Card
                </Link>
                <div className="savedCards">
                    {cards &&
                        cards.map((data, index) => (
                            <div className="savedCardsCard" key={index}>
                                <h4>
                                    {data.brand} ending in {data.last4}
                                </h4>
                                <h4>EXP. {data.exp_month + ' ' + data.exp_year}</h4>
                                <div className="d-flex justify-content-end mt-3">
                                    <Button
                                        onClick={() =>
                                            toggleFullScreenCardPopup(true, 'update', data)
                                        }
                                        id="editcard_button"
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        id="deletecard_button"
                                        onClick={() =>
                                            toggleFullScreenCardPopup(false, 'delete', data)
                                        }
                                    >
                                        Delete
                                    </Button>
                                    {/* )} */}
                                </div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="PaymentModal">
                {/* <Popup
            open={addBank}
            size="md"
            handleClose={() => setAddBank(false)}
            modaltitle="Bank Account"
        ></Popup>*/}
                <CardDetails
                    allCards={cards}
                    data={manageCard}
                    function={toggleFullScreenCardPopup}
                />
                <AddressDetails data={manage} function={toggleFullScreenPopup} />
                <PhoneValidationComponent
                    phoneVerify={phoneVerify}
                    formik={formik}
                    setPhoneVerify={setPhoneVerify}
                    changePhoneVerify={changePhoneVerify}
                />
            </div>
        </>
    )
}

export default MyAccount
