import React, { useState, useContext, useEffect } from 'react'
import UserContext from '../../product/context/user/userContext'
import AuthContext from '../../product/context/auth/authContext'
import CommonContext from '../../product/context/common/commonContext'
import CustomDialog from '../../custom/components/organisms/Dialog'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import PhoneVerify from '../PhoneVerify'

const PrivacyPolicy = () => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const commonContext = useContext(CommonContext)
    const { user, isAuthenticated, loadUser } = authContext
    const { static_page, getStaticPage } = commonContext
    const { updateProfile, responseStatus: updateProfileResponse } = userContext
    const [privacyPopup, setPrivacyPopup] = useState(false)
    const [phoneVerifyPopup, setPhoneVerifyPopup] = useState(false)

    useEffect(() => {
        if (isAuthenticated && !user.phone_verified) {
            getStaticPage({ id: 'privacy_policy' })
            setPrivacyPopup(true)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (updateProfileResponse) {
            if (updateProfileResponse.status === 'success') {
                if (updateProfileResponse.from === 'updateProfile') {
                    setPrivacyPopup(false)
                    loadUser()
                }
            } else {
                setPrivacyPopup(true)
            }
        }
    }, [updateProfileResponse])

    return (
        <>
            <CustomDialog
                className="modalView"
                function={() => {}}
                reason="backdropClick"
                open={false}
                // open={privacyPopup}
                title={''}
            >
                <div className="staticPages">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: static_page?.record?.content,
                        }}
                    ></p>
                    <div className="actionWrapper justify-content-center">
                        <PrimaryButton
                            id={`submit`}
                            type={`button`}
                            label={`Accept`}
                            onClick={() => {
                                setPhoneVerifyPopup(true)
                            }}
                        />
                    </div>
                </div>
            </CustomDialog>
            <PhoneVerify open={phoneVerifyPopup} closeFunction={setPhoneVerifyPopup} />
        </>
    )
}

export default PrivacyPolicy
