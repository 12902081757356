import React, { useContext, useEffect, useState } from 'react'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './Registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import RadioBox from '../../product/components/atoms/RadioBox'
import { useFormik, connect, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import PhoneValidation from './phoneValidation'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import AuthContext from '../../custom/context/auth/authContext'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import { FilterFramesOutlined } from '@material-ui/icons'
import { Document } from 'react-pdf'
import { Fade } from '@material-ui/core'
import { Modal } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import CustomCommonContext from '../../custom/context/common/commonContext'
import PhoneValidationComponent from '../../product/components/species/components/user/PhoneValidationComponent'
import { makeStyles } from '@material-ui/core/styles'
import { LOGO, SITE_NAME } from '../../Utils'
import AlertContext from '../../product/context/alert/alertContext'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))
const Registration = () => {
    const classes = useStyles()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const customCommonContext = useContext(CustomCommonContext)
    const { setAlert } = alertContext
    const { register, responseStatus, clearResponse } = authContext
    const { signupModal, setSignupModal, loginModal, setLoginModal } = customCommonContext

    const commonContext = useContext(CommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const [toggleSignup, setToggleSignup] = useState({
        popup: signupModal,
    })

    const { configFeatures } = commonContext
    const { static_page, getStaticPage } = productCommonContext
    let [termsLink, setTermsLink] = useState([])

    const [phoneVerify, setPhoneVerify] = useState(false)

    useEffect(() => {
        setToggleSignup({ popup: signupModal })
    }, [signupModal])

    // YUP Validation array to ensure all required fields are given values
    const validationArray = Yup.object({
        first_name: Yup.string().trim().max(100, 'Maxiumum 100 Characters').required('Required'),
        last_name: Yup.string().trim().max(100, 'Maxiumum 100 Characters').required('Required'),
        email: Yup.string()
            .email('Invalid Email Format')
            .max(250, 'Maximum 250 Characters')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .min(15, 'Incorrect format')
            .max(20, 'Incorrect format')
            .matches(/^[0-9- +()]*$/g, 'Only Numbers Are Allowed')
            .required('Required'),
        password: Yup.string()
            .trim()
            .required('Required')
            .min(10, 'Min 10 characters')
            .matches(
                /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
                'Password must contain 3 of the 4 following: Lowercase, Uppercase, Number, Symbol',
            ),
    })

    // Check response status is succesuful
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                if (responseStatus.status === 'success') {
                    setAlert(
                        `${responseStatus.message} Please check your email to verify your account`,
                        'success',
                    )
                    setLoginModal(true)
                    setSignupModal(false)
                    formik.resetForm()
                } else if (responseStatus.status != 'success') {
                    console.log(responseStatus, 'responseStatus')
                    setAlert(responseStatus.message, 'error')
                    formik.values.password = ''
                }
            } else if (responseStatus.from === 'checkValidation') {
                if (responseStatus.status !== 'success') {
                } else {
                    setActiveStep(1)
                    window.scrollTo(0, 200)
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        getStaticPage({ id: 'terms_of_use' })
    }, [])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    const viewTerms = () =>
        window.open(
            termsLink
                ? `${global.site_url}/uploads/static_files/${termsLink}`
                : '/assets/docs/termsOfService.pdf',
        )

    // Set Initial values
    const formik = useFormik({
        initialValues: {
            project: 'NPS',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            status: 'email_unverified',
            phone_verified: 0,
            verified_phonenum: '',
        },

        validationSchema: validationArray,
        onSubmit: (values) => {
            const castValues = validationArray.cast(values)
            // if (
            //     castValues.phone_verified === 1 &&
            //     castValues.verified_phonenum === castValues.phone
            // ) {
            register(castValues)
            //     setSignupModal(false)
            // } else {
            //     setPhoneVerify(true)
            // }
        },
    })

    useEffect(() => {
        if (formik.values.phone_verified === 1) {
            formik.submitForm()
        }
    }, [formik.values.phone_verified])

    // Mapped over for individual registration fields
    const IndividualRegistration = {
        formik: formik,
        data: [
            {
                label: 'First name',
                name: 'first_name',
                type: 'first_name',
                placeholder: 'Enter your first name',
                class: 'col-12',
            },
            {
                label: 'Last name',
                name: 'last_name',
                type: 'last_name',
                placeholder: 'Enter your last name',
                class: 'col-12',
            },
            {
                label: 'Email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter your email address',
                class: 'col-12',
            },
            // {
            //     label: 'Mobile Number',
            //     name: 'phone',
            //     type: 'phone',
            //     placeholder: 'Mobile Number',
            //     class: 'col-12',
            // },
            {
                label: 'Mobile Number',
                name: 'phone',
                type: 'phone',
                placeholder: 'Mobile Number',
                countryCodeEditable: false,
                class: 'col-12',
            },
            {
                label: 'Password',
                name: 'password',
                type: 'password',
                placeholder: 'Enter your password',
                class: 'col-12',
            },
        ],
    }

    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={toggleSignup.popup}
            className={`${classes.modal} customModal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={toggleSignup.popup}>
                <div className="regModal">
                    <button
                        type="button"
                        className="close"
                        id="close_signuppopup"
                        onClick={() => {
                            setSignupModal(false)
                            formik.resetForm()
                        }}
                    >
                        <span className="material-icons">close</span>
                    </button>
                    <Link to="/">
                        <img className="brandLogo" src={LOGO} alt={`${SITE_NAME} Logo`} />
                    </Link>
                    <h2>Quick Signup</h2>
                    <h4>Create your free account today.</h4>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mt-4">
                            {Object.values(mapData(IndividualRegistration))}
                        </div>
                        <div className="regActBox row">
                            <div className="col-12">
                                <PrimaryButton
                                    id="account_create"
                                    label={'Create My Account'}
                                    type="submit"
                                />
                                <p className="loginHelp">
                                    {'Already Have An Account'}{' '}
                                    <a
                                        className="cursorDecoy"
                                        id="loginhere_link"
                                        onClick={() => {
                                            setSignupModal(false), setLoginModal(true)
                                        }}
                                    >
                                        login here
                                    </a>
                                </p>
                            </div>
                        </div>
                    </form>
                    <PhoneValidationComponent
                        phoneVerify={false}
                        formik={formik}
                        setPhoneVerify={setPhoneVerify}
                        changePhoneVerify={changePhoneVerify}
                    />
                </div>
            </Fade>
        </Modal>
    )
}

export default Registration
